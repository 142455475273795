import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { slugify } from '../helpers'

export default (props) => {
  const data = useStaticQuery(graphql`
    query ContentfulProducts {
      allContentfulProduct {
        edges {
          node {
            id
            name
            priceInCents
            photos {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  console.log(data.allContentfulProduct.edges)
  return (
    <div className="flex flex-wrap items-center w-full">
      {data.allContentfulProduct.edges.filter(({node: product}) => {
        return product.id !== props.exclude
      }).map(({ node: product }) => (
        <Link to={'/product/' + slugify(product.name + " " + product.id)} 
          className={props.size === 'sm' ? "w-full md:w-1/6 p-2" : "w-full md:w-1/4 p-4"} 
          key={product.id}>
          <div>
            {product.photos && <Img fluid={product.photos[0].localFile.childImageSharp.fluid} />}
            <p className={`h-10 ${props.size === 'sm' ? "text-sm" : "text-base "}`} key={product.id}>{product.name}</p>
            <p className="font-bold text-sm">{(product.priceInCents / 100).toFixed(0)}Lek</p>
          </div>
        </Link>
      ))}
    </div>
  )
}
