import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = () => (
  <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "tradita.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section" fluid={imageData} backgroundColor={`#040e18`} >
          <div className="flex flex-col items-center content-center justify-around text-white relative">
            <div className="py-4 max-w-md text-center text-brand-900">
              <h1 className="font-bold text-3xl text-center leading-tight mb-4">Rikthimi i traditës</h1>
              <p className="text-center text-xl mb-4">Virginia sjell pjekeset e vogla per perdorim shtepiak dhe ju mundeson kthimin te tradita ma pajisjet dhe kafe jeshile shume cilesore.</p>
               <button className="bg-brand-500 hover:bg-green-600 rounded text-white text-center px-16 py-2 font-medium">Shop now</button>
            </div>
          </div>

        </BackgroundImage>
      )
    }
    }
  />
)

export default BackgroundSection