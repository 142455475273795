import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Header from "./header";
import { Location } from '@reach/router'
import {slugify} from "../helpers";
const BackgroundSection = (props) => (
  <StaticQuery query={graphql`
      query {
        site: site {
          siteMetadata {
            title
          }
        }
        desktop: file(relativePath: { eq: "hero cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
               render={data => {
                 // Set ImageData.
                 const imageData = data.desktop.childImageSharp.fluid

                 return (

                   <BackgroundImage Tag="section" fluid={imageData} backgroundColor={`#040e18`}>
                     <Location>
                       {({ location }) => {
                         console.log(location.pathname)
                         return location.pathname === '/' && <Header headerData={props} siteTitle={data.site.siteMetadata.title}/>

                       }}
                     </Location>

                     <div className="h-200 flex flex-col items-center content-center justify-around text-white relative">
                       <div className="max-w-lg text-center">
                         {/*<div className="text-left ml-4">*/}
                         {/*  <span className="text-xs text-brand-700 bg-green-400 px-4 py-1 rounded uppercase font-medium">New</span>*/}
                         {/*</div>*/}
                         <h1 className="font-bold text-6xl text-center leading-tight mb-4">Rizbuloni kafenë në
                           shtëpinë tuaj</h1>
                         <p className="text-center text-xl mb-4">Përzierjet dhe formatet e reja Virginia.</p>
                         <Link to={'/zgjidhni-kafene-tuaj'} className="bg-brand-500 hover:bg-brand-700 rounded text-white text-center px-16 py-2 font-medium">Zgjidhni kafene tuaj</Link>
                       </div>
                     </div>

                   </BackgroundImage>
                 )
               }
               }
  />
)

export default BackgroundSection