import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Skus from '../components/skus'
import ArticleList from '../components/articleList'

// import Img from "gatsby-image"



import StyledBackgroundSection from "../components/heroImage"
import StyledMiddleSection from "../components/traditionImage"

const IndexPage = ({location}) => {

  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <StyledBackgroundSection location={location} />
        <div className=" mb-8 mt-12">
          <div className=" container mx-auto flex items-center">
            <div className="-ml-6 mr-2">
              <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.358 16.0681C-9.92792 15.3039 3.08112 -5.94085 15.5656 17.3643C3.08112 -5.94085 27.853 -5.22158 14.0516 17.3194C27.853 -5.22158 39.6195 16.7424 13.3336 15.9782C39.6195 16.7424 26.6105 37.9872 14.126 14.682C26.6105 37.9872 1.83862 37.2679 15.6363 14.727C1.83862 37.2717 -9.92792 15.3039 16.358 16.0681Z" fill="#691C32"/>
              </svg>
            </div>

            <p className="font-bold text-gray-700">Shop</p>
          </div>
          <div className=" container mx-auto flex flex-wrap py-6">
            <Skus />
          </div>
          <StyledMiddleSection />

          <div className="mt-12 container mx-auto flex items-center">
            <div className="">
              <ArticleList />
            </div>
          </div>
        </div>

      </div>
    </Layout>
  )
}
export default IndexPage
